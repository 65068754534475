import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useThemeContext } from "../Themes/ThemeContextProvider";

const MuiMenu = (props) => {
  const { colors } = useThemeContext();
  const {
    menuListItems = [],
    MenuButtonIcon = <MoreVertIcon fontSize="small" />,
    buttonType = "icon",
    buttonStyle = {},
    iconsPosition = "start",
    style = {},
    teamsBox = null,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };
  const handleCloseMenu = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <div>
      {buttonType === "icon" && (
        <IconButton
          onClick={handleOpenMenu}
          aria-controls={`account-menu`}
          aria-haspopup="true"
          sx={buttonStyle}
        >
          {MenuButtonIcon}
        </IconButton>
      )}
      {buttonType === "outlined" && (
        <Button
          onClick={handleOpenMenu}
          aria-controls={`account-menu`}
          aria-haspopup="true"
          variant="outlined"
          color="secondary"
          sx={buttonStyle}
        >
          {MenuButtonIcon}
        </Button>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            border: `1px solid ${colors.borderPrimary}`,
            // marginLeft: "-100px",
            background: "#0C111D",
            color: colors.textPrimary,
          },
        }}
        sx={{
          ".MuiList-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          minWidth: "140px",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {teamsBox &&
          React.cloneElement(teamsBox, { onSelectTeam: handleCloseMenu })}
        {menuListItems.map((item) => (
          <MenuItem
            key={item?.title}
            sx={{
              borderBottom: "none",
              "&:last-child": {
                borderTop: `1px solid ${colors.borderSecondary}`,
              },
              "&:hover": {
                background: colors.bgSecondary,
              },
              padding: "12px 14px 12px 14px",

              minWidth: "240px",
              background: colors.bgPrimary,
            }}
            style={{ ...style }}
            onClick={(e) => {
              item?.onClick();
              handleCloseMenu(e);
            }}
          >
            {item?.icon && iconsPosition === "start" && (
              <ListItemIcon
                sx={{
                  minWidth: "20px !important",
                  ".MuiListItemIcon-root": {
                    minWidth: "20px",
                  },
                  color: item?.colorRed ? "rgba(249, 112, 102, 1)" : "",
                }}
              >
                {item?.icon}
              </ListItemIcon>
            )}
            <ListItemText>
              <div
                className="text-sm-medium mx-1"
                style={{
                  color: item?.colorRed ? "rgba(249, 112, 102, 1)" : "",
                }}
              >
                {item?.title}
              </div>
            </ListItemText>
            {item?.icon && iconsPosition === "end" && (
              <ListItemIcon
                sx={{
                  minWidth: "20px !important",
                  ".MuiListItemIcon-root": {
                    minWidth: "20px",
                  },
                  color: item?.colorRed ? "rgba(249, 112, 102, 1)" : "",
                }}
              >
                {item?.icon}
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MuiMenu;
