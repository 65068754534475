import FilledTextField from "@Components/UI/FilledTextField";
import MuiTable from "@Components/UI/MuiTable";
import Navigation from "@Components/onboarding/Navigation";
import { ReactComponent as SearchIcon } from "@Images/search-lg.svg";
import { getBenchMarkResult, getCalculation } from "@Services.App/api";
import debounceService from "@Services.App/filterOptimizer";
import snackbarService from "@Services.App/snackbar";
import { llmModelsIcon } from "@Services/constants";
import {
  formatCurrency,
  formatTimestampToDate,
} from "@Services/generalFunctions";
import { handleLoading } from "@Store/collectionsSlice";
import { NavbarLogo, NonSelectedIcon, SelectedRoundIcon } from "@UI/IconPack";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { Button, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "../Themes/ThemeContextProvider";
import MuiSelect from "../UI/MuiSelect";
import "./Features.scss";

const Pricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { colors, mode } = useThemeContext();
  const [selectedTag, setSelectedTag] = useState("token");
  const [input, setInput] = useState("");
  const [outPut, setOutPut] = useState("");
  const [limit, setLimit] = useState(10);
  const [llmModel, SetLlmModel] = useState("gpt-4o");
  const [modelData, setModelData] = useState([]);
  const [apiCalls, setApiCalls] = useState("");
  const [dataItems, setDataItems] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [benchmarkResults, setBenchmarkResults] = useState([]);
  function roundToDecimalPlaces(num, precision) {
    var multiplier = Math.pow(10, precision);
    return Math.round(num * multiplier) / multiplier;
  }

  const menuList = [
    {
      value: "gpt-4o",
      label: "gpt-4o",
    },
    {
      value: "gpt-3.5-turbo",
      label: "gpt-3.5-turbo",
    },
    {
      value: "gpt-3.5-turbo-0125",
      label: "gpt-3.5-turbo-0125",
    },
    {
      value: "gpt-3.5-turbo-1106",
      label: "gpt-3.5-turbo-1106",
    },
    {
      value: "gemini-pro",
      label: "gemini-pro",
    },
    {
      value: "gemini-1.5-pro-latest",
      label: "gemini-1.5-pro-latest",
    },
    {
      value: "jamba-instruct-preview",
      label: "jamba-instruct-preview",
    },
    {
      value: "gpt-4-vision-preview",
      label: "gpt-4-vision-preview",
    },
    {
      value: "gpt-4",
      label: "gpt-4",
    },
    {
      value: "jamba-instruct",
      label: "jamba-instruct",
    },
    {
      value: "gpt-3.5-turbo-16k",
      label: "gpt-3.5-turbo-16k",
    },
    {
      value: "command-r-plus",
      label: "command-r-plus",
    },
    {
      value: "command-r",
      label: "command-r",
    },
    {
      value: "claude-3-haiku-20240307",
      label: "claude-3-haiku-20240307",
    },
    {
      value: "claude-3-sonnet-20240229",
      label: "claude-3-sonnet-20240229",
    },
    {
      value: "claude-3-opus-20240229",
      label: "claude-3-opus-20240229",
    },
  ];
  const columns = [
    {
      field: "name",
      title: "Name",
      width: "30%",
      renderAction: (row) => (
        <span className="text-primary flex gap-2 items-center">
          {
            llmModelsIcon("30px", mode === "light" ? "black" : "white")[
              row?.model
            ]
          }
          {row?.name}
        </span>
      ),
    },
    {
      field: "tokens",
      title: "Scalaix Tokens",
      renderAction: (row) => (
        <span className="text-tertiary">{row?.tokens}</span>
      ),
    },
    {
      field: "input_price",
      title: "1 M Input Tokens",
      renderAction: (row) => (
        <span className="text-tertiary">
          {formatCurrency(row?.input_price)}
        </span>
      ),
    },
    {
      field: "output_price",
      title: "1 M Output Tokens",
      renderAction: (row) => (
        <span className="text-tertiary">
          {formatCurrency(row?.output_price)}
        </span>
      ),
    },
    // {
    //   field: "output_token_price",
    //   title: "O/p Tokens Price",
    //   renderAction: (row) => (
    //     <span className="text-tertiary">
    //       {roundToDecimalPlaces(row?.output_token_price, 6)}
    //     </span>
    //   ),
    // },
    // {
    //   field: "input_token_price",
    //   title: "I/P  Tokens  Price",
    //   renderAction: (row) => (
    //     <span className="text-tertiary">
    //       {roundToDecimalPlaces(row?.input_token_price, 6)}
    //     </span>
    //   ),
    // },
    // {
    //   field: "total_token_price",
    //   title: "Total Tokens Price",
    //   renderAction: (row) => (
    //     <span className="text-tertiary">
    //       {/* {row?.total_token_price} */}
    //       {roundToDecimalPlaces(row?.total_token_price, 6)}
    //     </span>
    //   ),
    // },
    {
      field: "updated_at",
      title: "Update time",
      renderAction: (row) => (
        <span className="text-tertiary">
          {formatTimestampToDate(row?.updated_at, "MMMM DD, YYYY")}
        </span>
      ),
    },
  ];

  const handleGetCalculation = (
    input,
    outPut,
    apiCalls,
    page = 1,
    search_key = ""
  ) => {
    if (input == "") {
      snackbarService.error({
        title: `Input ${selectedTag} can't be empty.`,
      });
      return;
    }
    if (outPut == "") {
      snackbarService.error({
        title: `Output ${selectedTag} can't be empty.`,
      });
      return;
    }
    if (apiCalls == "") {
      snackbarService.error({
        title: `ApiCalls call is empty`,
      });
      return;
    }
    dispatch(handleLoading(true));
    Promise.all([
      getCalculation({
        params: {
          input_tokens: input,
          output_token: outPut,
          type: selectedTag,
          api_calls: apiCalls,
          page: page,
          limit: limit,
          searchKey: search_key,
        },
      }),
      getCalculation({
        params: {
          input_tokens: input,
          output_token: outPut,
          type: selectedTag,
          api_calls: apiCalls,
          page: 1,
          limit: limit,
          searchKey: search_key,
          model_name: llmModel,
        },
      }),
    ])
      .then(([res1, res2]) => {
        setDataItems(res1);

        setModelData(res2.items);
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };
  const optimiser = useCallback(
    debounceService.optimize((input, outPut, apiCalls, page_num, text) => {
      handleGetCalculation(input, outPut, apiCalls, page_num, text);
    }, 500),
    []
  );
  const getBackMarkResultHandler = () => {
    dispatch(handleLoading(true));
    getBenchMarkResult({
      params: {},
    })
      .then((res) => {
        setBenchmarkResults(res?.compare_data ? res?.compare_data : []);
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };
  useEffect(() => {
    getBackMarkResultHandler();
  }, []);
  return (
    <div className="px-[5%]" style={{ background: "black" }}>
      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container-main-cur">
          <Navigation />
          <div className="flex flex-col gap-4 -mt-[5%] data-pr-one">
            <div className="text-md-semibold text-[#94969C]">
              LLMs Comparison
            </div>
            <div className="display-md-semibold text-white">
              Discover which LLM is best suited for your specific needs
            </div>
            <div className="text-xl-regular text-[#94969C]">
              Scalaix enables you to test and compare various LLMs according to
              your unique requirements and seamlessly switch between them to
              manage your costs and response times effectively.
            </div>
          </div>
        </div>

        <div className="container-main-cur">
          <div className="data-pr-2">
            <div className="data-grid ">
              {benchmarkResults.map((item, index) => {
                return (
                  <div className="model-data-block">
                    <div className="flex justify-between items-center">
                      <div className="data-num">#{index + 1}</div>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/s1/pricing/${item?.model_name}`, {
                            state: {
                              model: item?.model,
                              modelName: item?.model_name,
                              size: "56px",
                              color: "white",
                            },
                          })
                        }
                      >
                        <CallMadeIcon />
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <div>{llmModelsIcon("30px", "white")[item?.model]}</div>
                      <div className="text-md-medium ">{item?.model_name}</div>
                    </div>
                    <div>
                      <div className="text-md-medium data-tittle">
                        Average response time
                      </div>
                      <div className="text-md-regular">
                        {roundToDecimalPlaces(item?.average_response_time, 5)}{" "}
                        sec
                      </div>
                    </div>
                    <div>
                      <div className="text-md-medium data-tittle">
                        Average cost
                      </div>
                      <div className="text-md-regular">
                        {roundToDecimalPlaces(item?.average_cost, 5)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-4 ">
            <div className="display-md-semibold text-white">
              LLMs cost calculator
            </div>
            <div className="text-xl-regular text-[#94969C]">
              Scalaix enables you to test and compare various LLMs according to
              your unique requirements and seamlessly switch between them to
              manage your costs and response times effectively.
            </div>
          </div>
        </div>

        <div className="container-main-cur mt-16 ">
          <div className="block-pr4">
            <div className=" flex gap-1 md:gap-2 justify-center items-center mb-[4%]">
              <h1 className="text-secondary text-sm md:text-lg">
                Select your preffered LLM :{" "}
              </h1>
              <div className="w-36 md:w-44">
                <MuiSelect
                  menuItems={menuList}
                  value={llmModel}
                  onChange={(e) => SetLlmModel(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-3 items-center justify-center mb-8 inputblock">
              {[
                { tag: "token", name: "Tokens" },
                { tag: "word", name: "Words" },
                { tag: "char", name: "Characters" },
              ].map((item) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedTag(item.tag);
                    }}
                    className={
                      selectedTag == item.tag
                        ? "none-selected-block w-[240px]"
                        : "selected-block w-[240px]"
                    }
                  >
                    <div className="text-md-medium text-white">{item.name}</div>{" "}
                    <div className="block-inner1">
                      {selectedTag == item.tag ? (
                        <SelectedRoundIcon />
                      ) : (
                        <NonSelectedIcon />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex gap-3 items-center justify-center inputblock">
              <div>
                <div className="text-sm-medium text-secondary mb-3">
                  Input {selectedTag}
                </div>
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    background: "#0C111D",
                    "& .MuiOutlinedInput-input": {
                      // background: "red",
                      // borderLeft: "1px solid #D0D5DD",

                      color: "white",
                    },
                    color: "white",
                  }}
                  // onWheel={(e) => e.target.blur()}
                  value={input}
                  onChange={(e) => {
                    setInput(e.target.value);
                  }}
                  type="number"
                  fullWidth
                  placeholder="Enter input tokens"
                />
              </div>
              <div>
                <div className="text-sm-medium text-secondary mb-3">
                  Output {selectedTag}
                </div>
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    "& .MuiOutlinedInput-input": {
                      // background: "red",
                      // borderLeft: "1px solid #D0D5DD",

                      color: "white",
                    },
                    background: "#0C111D",
                  }}
                  value={outPut}
                  onChange={(e) => {
                    setOutPut(e.target.value);
                  }}
                  type="number"
                  fullWidth
                  placeholder="Enter output tokens"
                />
              </div>
              <div>
                <div className="text-sm-medium text-secondary mb-3">
                  API calls
                </div>
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    "& .MuiOutlinedInput-input": {
                      // background: "red",
                      // borderLeft: "1px solid #D0D5DD",

                      color: "white",
                    },
                    background: "#0C111D",
                  }}
                  value={apiCalls}
                  onChange={(e) => {
                    setApiCalls(e.target.value);
                  }}
                  type="number"
                  fullWidth
                  placeholder="Enter API calls"
                />
              </div>
            </div>

            <div className="flex gap-3 items-center justify-center mt-8">
              <Button
                variant="contained"
                width={{ width: "120px" }}
                onClick={() => {
                  setSearchKey("");
                  handleGetCalculation(
                    input,
                    outPut,
                    apiCalls,
                    1,
                    "",
                    llmModel
                  );
                }}
              >
                Calculate cost
              </Button>
            </div>
          </div>
        </div>
        <div className="container-main-cur mt-6 bg-secondary rounded-lg ">
          {modelData.map((item) => (
            <div
              key={item.id}
              className="px-24 py-5 flex flex-col md:flex-row items-start md:items-center justify-between gap-4"
            >
              <div className="flex flex-col items-start md:items-center">
                <h2 className="text-md text-tertiary">Input token price</h2>
                <p className="text-xl text-primary">
                  {" "}
                  ${Number(item.input_token_price).toFixed(3)}
                </p>
              </div>
              <div className="flex flex-col items-start md:items-center">
                <h2 className="text-md text-tertiary">Output token price</h2>
                <p className="text-xl text-primary">
                  {" "}
                  ${Number(item.output_token_price).toFixed(3)}
                </p>
              </div>
              <div className="flex flex-col items-start md:items-center">
                <h2 className="text-md text-tertiary">Total token price</h2>
                <p className="text-xl text-primary">
                  {" "}
                  ${Number(item.total_token_price).toFixed(3)}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div
          className="container-main-cur mt-16"
          style={{ minHeight: "200px" }}
        >
          {dataItems?.items?.length || searchKey != "" ? (
            <div
              className="border-secondary rounded-lg"
              style={{ background: mode === "dark" ? "#0C111D" : "white" }}
            >
              <div className="flex justify-between items-center py-6 px-4 border-t-1 border-r-1 border-l-1 border-secondary rounded-t-lg inputblock">
                <div className="display-md-semibold">LLMs</div>
                <div>
                  <div className="w-full  text-filed-search">
                    <FilledTextField
                      placeholder={"Search"}
                      type="text"
                      name="title"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={searchKey}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        optimiser(input, outPut, apiCalls, 1, e.target.value);
                      }}
                      startIcon={<SearchIcon />}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: mode === "dark" ? "#0C111D" : "white",
                  color: "white",
                }}
              >
                <MuiTable
                  data={dataItems?.items}
                  columns={columns}
                  roundedBorder={false}
                  page={dataItems?.page}
                  pages={dataItems?.pages}
                  backgroundColor="red"
                  onPageChange={(v, page) =>
                    handleGetCalculation(
                      input,
                      outPut,
                      apiCalls,
                      page,
                      searchKey
                    )
                  }
                  tableTitle={"LLMs"}
                  hideFilter={false}
                />{" "}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="container-main-cur bg-black">
        <div className="datafooter items-center justify-between py-[32px]  w-full">
          <div className="flex items-center ">
            <div>
              <NavbarLogo width={"142px"} height={"32px"} />
            </div>
          </div>
          <div className="text-md-regular  text-[#94969C]">
            © 2024 Scalaix. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
