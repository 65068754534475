import moment from "moment";
import snackbar from "./app/snackbar";

export const formatTimestampToDate = (
  timestamp,
  format = "MM/DD/YYYY hh:mm A"
) => {
  return moment.unix(Number(timestamp)).format(format);
};

export const isValidTimestamp = (timestamp) => moment.unix(timestamp).isValid();

export const downloadJSON = (jsonObject, fileName) => {
  const jsonString = JSON.stringify(jsonObject);
  const blob = new Blob([jsonString], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
};
export const downloadCSV = (csvString, fileName) => {
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
export const downloadXML = (xmlString, fileName) => {
  const blob = new Blob([xmlString], { type: "application/xml" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const downloadImage = (imageUrl, fileName) => {
  let a = document.createElement("a");
  a.download = fileName;
  a.href = imageUrl;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const isValidEmail = (email) => {
  // const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};
export const isValidPassword = (password) => {
  return password.match(
    "^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9-_.!@#$%^&*]{8,16}$"
  );
};

export const isValidURL = (url) => {
  var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlPattern.test(url);
};

export const copyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.select();
  textArea.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(textArea.value);
  snackbar.success({
    title: "text copied successfully",
  });
};

export const hideKeys = (key, start = 0, end = 0, hideWith = "*") => {
  return (
    key?.slice(0, start) +
    `${hideWith}`.repeat(key?.length - (start + end)) +
    key?.slice(key?.length - end, key?.length)
  );
};

export const convertFileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const isValidXML = (xmlString) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    const parseErrors = xmlDoc.getElementsByTagName("parsererror");
    return parseErrors.length === 0;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const formatOutputKeysToSave = (prompt) => {
  let outputKeys = {};
  Object.keys(prompt?.output_keys).forEach((key) => {
    if (key === "csv") {
      outputKeys[key] = prompt?.output_keys[key].join(";");
    } else if (key === "xml") {
      outputKeys[key] = prompt?.output_keys[key];
    } else if (key === "json") {
      try {
        outputKeys[key] = JSON.stringify(prompt?.output_keys[key]);
      } catch (error) {
        outputKeys[key] = prompt?.output_keys[key];
      }
    }
  });
  return outputKeys;
};

export const formatOutputKeysToEdit = (prompt) => {
  let outputKeys = {};
  Object.keys(prompt?.output_keys).forEach((key) => {
    if (key === "csv") {
      outputKeys[key] = prompt?.output_keys[key].split(";");
    } else if (key === "xml") {
      outputKeys[key] = prompt?.output_keys[key].toString();
    } else if (key === "json") {
      try {
        outputKeys[key] = JSON.parse(prompt?.output_keys[key]);
      } catch (error) {
        outputKeys[key] = prompt?.output_keys[key];
      }
    }
  });
  return outputKeys;
};

export const formatCurrency = (amount, currency = "USD") => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  return formatter.format(amount);
};

export const formatNumber = (number, roundup = false) => {
  const roundedNumber = roundup ? Math.round(number) : number;
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(roundedNumber);
};

export const convertCreditsIntoAmount = (credits = 0) =>
  formatCurrency(credits * 0.000005);

export const convertToToken = (credits = 0) => {
  if (credits == 0) {
    return 0;
  }
  if (credits <= 0) {
    snackbar.error({
      title: "less than zero not allowed",
    });

    return;
  }
  return parseInt(credits * 200000);
};

export const bytesToSize = (bytes) => {
  if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  }
};

export const getElementWidthById = (id) =>
  document.getElementById(id)?.clientWidth || 0;

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};
