import CollectionSelect from "@Components/AuthComponents/collections/CollectionSelect";
import NewCollection from "@Components/AuthComponents/collections/NewCollection";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import dialogService from "@Services.App/dialog";
import { FileTypeIcon } from "@UI/IconPack";
import MuiModal from "@UI/MuiModal";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AddCollection = (props) => {
  const [openModel, setOpenModel] = useState(false);
  const navigate = useNavigate();
  const {
    mode = "new",
    collection = {},
    callCollections = () => {},
    onClose = () => {},
    allCollections,
    searchCollection,
  } = props;

  useEffect(() => {
    if (mode === "edit") {
      setOpenModel(true);
    }
  }, [mode]);

  const showRenderDialog = (props) => {
    dialogService.planDialog({
      content: <CollectionSelect />,
      contentProps: props,
    });
  };

  const handlerRedirect = (id, res) => {
    navigate(`/onboard/collections/${id}`, {
      state: { collection: res },
      replace: true,
    });
  };
  const handleModalClose = () => {
    setOpenModel(false);
    onClose();
  };
  const handleModalOpen = () => setOpenModel(true);
  return (
    <>
      <MuiModal width={"60%"} open={openModel} handleClose={handleModalClose}>
        <NewCollection
          handleModal={handleModalClose}
          callCollections={callCollections}
          mode={mode}
          collection={collection}
        />
      </MuiModal>

      {allCollections?.items?.length !== 0 && searchCollection == "" && (
        <div>
          <Button
            startIcon={<Add />}
            onClick={() => {
              showRenderDialog({
                handlerRedirect: (id, res) => {
                  handlerRedirect(id, res);
                },
                handleModalOpen: () => {
                  handleModalOpen();
                },
              });
            }}
            variant="contained"
            color="primary"
            className="w-[194px] h-[44px]"
          >
            Create Collection
          </Button>
        </div>
      )}

      {allCollections?.items?.length == 0 && searchCollection == "" && (
        <div className="text-4xl pb-14 text-center h-[93.5%] bg-primary flex flex-col justify-center items-center">
          <div className="relative flex flex-col items-center justify-center">
            <FileTypeIcon />
            <div className="absolute bottom-0">
              <p className="text-md-semibold text-primary  mt-4">
                There are no collections yet
              </p>
              <p className="text-sm-regular text-tertiary z-10 mt-2">
                Begin by creating a collection from scratch or searching for a
                template that best fits your needs.
              </p>

              <div className="flex gap-4 items-center mt-8 justify-center">
                <Button
                  onClick={() => {
                    navigate(`/onboard/templates`);
                  }}
                  variant="secondaryblack"
                >
                  Explore templates
                </Button>
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    if (window.location.pathname == "/onboard/templates") {
                      handleModalOpen();
                    } else {
                      showRenderDialog({
                        handlerRedirect: (id, res) => {
                          handlerRedirect(id, res);
                        },
                        handleModalOpen: () => {
                          handleModalOpen();
                        },
                      });
                    }
                  }}
                  variant="contained"
                  color="primary"
                >
                  Create collection
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCollection;
